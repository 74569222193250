import React, { useState, useEffect } from "react";
import {
  Grid,
  Paper,
  Typography,
  Switch,
  FormControlLabel,
  Snackbar,
  Alert,
  Divider,
  styled,
  Card,
  CardContent,
  Box,
  IconButton,
  CircularProgress,
} from "@mui/material";
import axios from "axios";
import { domain } from "../../Components/config";
import {
  PendingActions as PendingIcon,
  AttachMoney as MoneyIcon,
  Block as BlockIcon,
  Percent as PercentIcon,
} from "@mui/icons-material";
import {
  PersonAdd,
  AttachMoney,
  MoneyOff,
  AccountBalance,
  People,
  Pending,
  CheckCircle,
  MonetizationOn,
  WebAsset,
  SupportAgent,
  AccountBalanceWallet,
  Casino,
  Settings,
} from "@mui/icons-material";

const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 48, // updated width to 48
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(22px)", // adjusted translateX for the new width
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: "#7B61FF",
        opacity: 1,
        border: 0,
        ...theme.applyStyles("dark", {
          backgroundColor: "#2ECA45",
        }),
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color: theme.palette.grey[100],
      ...theme.applyStyles("dark", {
        color: theme.palette.grey[600],
      }),
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: 0.7,
      ...theme.applyStyles("dark", {
        opacity: 0.3,
      }),
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: "#E9E9EA",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
    ...theme.applyStyles("dark", {
      backgroundColor: "#39393D",
    }),
  },
}));

const Dashboard = () => {
  const [data, setData] = useState(0);
  const [userBalance, setUserBalance] = useState(0);
  const [todayrecharge, setTodayrecharge] = useState(0);
  const [todayWithdrawal, setTodayWithdrawal] = useState(0);
  const [totalUser, setTotalUser] = useState(0);
  const [pendingRecharge, setPendingRecharge] = useState(0);
  const [successRecharge, setSuccessRecharge] = useState(0);
  const [totalWithdrawal, setTotalWithdrawal] = useState(0);
  const [maintenanceMode, setMaintenanceMode] = useState(false);
  const [withdrawalRequests, setWithdrawalRequests] = useState(0);
  const [pendingComplaints, setPendingComplaints] = useState(0);
  const [needToDepositFirst, setNeedToDepositFirst] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  // New state variables for game winning type
  const [isRandomWinning, setIsRandomWinning] = useState(false);
  const [isGameWinningLoading, setIsGameWinningLoading] = useState(false);

  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "info",
  });

  useEffect(() => {
    fetchDashboardData();
    fetchGameWinningType();
  }, []);

  const fetchDashboardData = async () => {
    try {
      const responses = await Promise.all([
        axios.get(`${domain}/todays-registrations`, { withCredentials: true }),
        axios.get(`${domain}/admin/normal-users-wallet-summary`, {
          withCredentials: true,
        }),
        axios.get(`${domain}/recharge-today`, { withCredentials: true }),
        axios.get(`${domain}/total-withdraw-amount-last-24-hours`, {
          withCredentials: true,
        }),
        axios.get(`${domain}/total-registrations`, { withCredentials: true }),
        axios.get(`${domain}/pending-recharge`, { withCredentials: true }),
        axios.get(`${domain}/success-recharge`, { withCredentials: true }),
        axios.get(`${domain}/total-withdrawl-amount`, {
          withCredentials: true,
        }),
        axios.get(`${domain}/maintenance-mode`, { withCredentials: true }),
        axios.get(`${domain}/pending-withdrawals-count`, {
          withCredentials: true,
        }),
        // axios.get(`${domain}/pending-complaints-count`, {
        //   withCredentials: true,
        // }),
        axios.get(`${domain}/need-to-deposit-first`, { withCredentials: true }),
      ]);

      setData(responses[0].data.countOfDailyUsers || 0);
      setUserBalance(responses[1].data.totalWalletAmount || 0);
      setTodayrecharge(responses[2].data.totalRechargeAmount || 0);
      setTodayWithdrawal(responses[3].data.totalWithdrawAmount || 0);
      setTotalUser(responses[4].data.count || 0);
      setPendingRecharge(responses[5].data.pendingAmount || 0);
      setSuccessRecharge(responses[6].data.successAmount || 0);
      setTotalWithdrawal(responses[7].data.completeWithdrawAmount || 0);
      setMaintenanceMode(responses[8].data.maintenanceMode || false);
      setWithdrawalRequests(responses[9].data.pendingWithdrawCount || 0);
      // setPendingComplaints(responses[10].data.pendingComplaintsCount || 0);
      setNeedToDepositFirst(
        responses[10].data.data.needToDepositFirst || false
      );
    } catch (error) {
      console.error("Error fetching dashboard data:", error);
      setSnackbar({
        open: true,
        message: "Error fetching dashboard data",
        severity: "error",
      });
      // Set default values in case of error
      setData(0);
      setUserBalance(0);
      setTodayrecharge(0);
      setTodayWithdrawal(0);
      setTotalUser(0);
      setPendingRecharge(0);
      setSuccessRecharge(0);
      setTotalWithdrawal(0);
      setMaintenanceMode(false);
      setWithdrawalRequests(0);
      setPendingComplaints(0);
      setNeedToDepositFirst(false);
    }
  };

  // Add new function to fetch game winning type
  const fetchGameWinningType = async () => {
    try {
      const response = await axios.get(`${domain}/game-winning-type`, {
        withCredentials: true,
      });
      setIsRandomWinning(response.data.data.isRandomWinning);
    } catch (error) {
      console.error("Error fetching game winning type:", error);
      setSnackbar({
        open: true,
        message: "Error fetching game winning type settings",
        severity: "error",
      });
    }
  };

  // Add new function to handle game winning type toggle
  const handleToggleGameWinningType = async () => {
    setIsGameWinningLoading(true);
    try {
      const response = await axios.put(
        `${domain}/game-winning-type`,
        { isRandomWinning: !isRandomWinning },
        { withCredentials: true }
      );

      if (response.data.success) {
        setIsRandomWinning(response.data.data.isRandomWinning);
        setSnackbar({
          open: true,
          message: "Game winning type updated successfully",
          severity: "success",
        });
      } else {
        throw new Error(
          response.data.message || "Failed to update game winning type"
        );
      }
    } catch (error) {
      console.error("Error while toggling game winning type:", error);
      setSnackbar({
        open: true,
        message: "Failed to update game winning type",
        severity: "error",
      });
    } finally {
      setIsGameWinningLoading(false);
    }
  };

  const handleToggleDepositFirst = async () => {
    setIsLoading(true);
    try {
      const response = await axios.post(
        `${domain}/update-need-to-deposit-first`,
        {},
        { withCredentials: true }
      );

      if (response.data.success) {
        setNeedToDepositFirst(response.data.data.needToDepositFirst);
        setSnackbar({
          open: true,
          message: "Setting updated successfully",
          severity: "success",
        });
      } else {
        throw new Error(response.data.message || "Failed to update setting");
      }
    } catch (error) {
      console.error("Error while toggling needToDepositFirst:", error);
      setSnackbar({
        open: true,
        message: "Failed to update setting",
        severity: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  const IconWrapper = styled(Box)(({ bgcolor }) => ({
    backgroundColor: bgcolor,
    borderRadius: "8px",
    padding: "8px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  }));

  const StyledCard = styled(Box)({
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.05)",
    borderRadius: "12px",
    backgroundColor: "#fff",
    // margin: "10px"
  });

  const MetricBox = styled(Box)({
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    // padding: '8px',
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.02)",
      cursor: "pointer",
    },
  });

  const dashboardData = [
    {
      heading: "Active Users",
      value: data,
      icon: <PersonAdd />,
      border: "#22C55E",
      iconBgColor: "#DCFCE7",
      iconColor: "#22C55E",
    },
    {
      heading: "Total Users",
      value: totalUser,
      icon: <People />,
      border: "#6366F1",
      iconBgColor: "#EEF2FF",
      iconColor: "#6366F1",
    },
    {
      heading: "User Balance",
      value: `₹${userBalance}`,
      icon: <AccountBalance />,
      border: "#EF4444",
      iconBgColor: "#FEE2E2",
      iconColor: "#EF4444",
    },
    {
      heading: "Website Mode",
      value: maintenanceMode ? "On" : "Off",
      icon: <WebAsset />,
      border: "#F97316",
      iconBgColor: "#FFEDD5",
      iconColor: "#F97316",
    },
  ];

  const depositData = [
    {
      label: "Today's Recharge",
      value: todayrecharge,
      icon: <AttachMoney sx={{ color: "#22c55e" }} />,
      bgcolor: "#f0fdf4",
    },
    {
      label: "Pending Recharges",
      value: pendingRecharge,
      bgcolor: "#fff7ed",
      icon: <PendingIcon sx={{ color: "#f97316" }} />,
    },
    {
      label: "Success Recharge",
      value: successRecharge,
      bgcolor: "#fef2f2",
      icon: <PercentIcon sx={{ color: "#ef4444" }} />,
    },
    // {
    //   heading: "Pending Complaints",
    //   value: pendingComplaints,
    //   icon: <SupportAgent />,
    // },
  ];

  const withdrawalData = [
    {
      label: "Today's Withdrawal",
      value: todayWithdrawal,
      icon: <MoneyIcon sx={{ color: "#22c55e" }} />,
      bgcolor: "#f0fdf4",
    },
    {
      label: "Total Withdrawal",
      value: totalWithdrawal,
      icon: <PercentIcon sx={{ color: "#f97316" }} />,
      bgcolor: "#fff7ed",
    },
    {
      label: "Withdrawal Requests",
      value: withdrawalRequests,
      icon: <AccountBalanceWallet sx={{ color: "#a855f7" }} />,
      bgcolor: "#faf5ff",
    },
  ];

  return (
    <Box sx={{ minHeight: "85vh", px: "20px", backgroundColor: "#F3F3F9" }}>
      <Typography
        variant="h5"
        gutterBottom
        sx={{ marginBottom: 4, color: "#071251", fontWeight: "bold" }}
      >
        Dashboard
      </Typography>

      {/* Dashboard Grid */}
      <Grid container spacing={3}>
        {dashboardData.map((item, index) => (
          <Grid item xs={12} sm={6} md={3} key={index}>
            <Paper
              elevation={0}
              sx={{
                p: 2.5,
                backgroundColor: "#ffffff",
                border: "1px solid",
                borderColor: item.border,
                borderRadius: 2,
                "&:hover": {
                  boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.05)",
                },
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "flex-start",
                  justifyContent: "space-between",
                }}
              >
                <Box sx={{ display: "flex", gap: 2, flex: 1 }}>
                  <Box
                    sx={{
                      width: 48,
                      height: 48,
                      backgroundColor: item.iconBgColor,
                      borderRadius: 1.5,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    {React.cloneElement(item.icon, {
                      sx: { color: item.iconColor, fontSize: 24 },
                    })}
                  </Box>
                  <Box>
                    <Typography
                      variant="body2"
                      sx={{
                        color: "text.secondary",
                        fontWeight: 500,
                        mb: 0.5,
                        fontSize: "0.875rem",
                      }}
                    >
                      {item.heading}
                    </Typography>
                    <Typography
                      variant="h6"
                      sx={{
                        fontWeight: 700,
                        color: "text.primary",
                        fontSize: "1.5rem",
                        lineHeight: 1.2,
                      }}
                    >
                      {item.value}
                    </Typography>
                  </Box>
                </Box>
                <IconButton
                  size="small"
                  sx={{
                    color: "rgba(0, 0, 0, 0.54)",
                    padding: 0,
                    "&:hover": {
                      backgroundColor: "transparent",
                      color: "rgba(0, 0, 0, 0.87)",
                    },
                  }}
                ></IconButton>
              </Box>
            </Paper>
          </Grid>
        ))}
      </Grid>

      <Grid container xs={12} sx={{ gap: 2, mt: 2, flexWrap: { xs: "wrap", sm: "nowrap" } }}>

        {/* Deposit Grid */}
        <Grid item xs={12} sm={12} md={6}>
          <StyledCard>
            <CardContent>
              <Typography
                variant="h6"
                sx={{
                  fontWeight: 600,
                  color: "#374151",
                  mb: 2,
                }}
              >
                Deposits
              </Typography>
              <Grid container spacing={2}>
                {depositData.map((metric, index) => (
                  <Grid item xs={12} sm={6} key={index}>
                    <MetricBox>
                      <Box
                        sx={{ display: "flex", alignItems: "center", gap: 1 }}
                      >
                        <IconWrapper bgcolor={metric.bgcolor}>
                          {metric.icon}
                        </IconWrapper>
                        <Box>
                          <Typography
                            variant="body1"
                            sx={{
                              fontWeight: 600,
                              color: "#111827",
                            }}
                          >
                            {metric.value}
                          </Typography>
                          <Typography
                            variant="body2"
                            sx={{
                              color: "#6b7280",
                            }}
                          >
                            {metric.label}
                          </Typography>
                        </Box>
                      </Box>
                    </MetricBox>
                  </Grid>
                ))}
              </Grid>
            </CardContent>
          </StyledCard>
        </Grid>

        {/* Withdrawals Grid */}
        <Grid item xs={12} sm={12} md={6}>
          <StyledCard>
            <CardContent>
              <Typography
                variant="h6"
                sx={{
                  fontWeight: 600,
                  color: "#374151",
                  mb: 2,
                }}
              >
                Withdrawals
              </Typography>
              <Grid container spacing={2}>
                {withdrawalData.map((metric, index) => (
                  <Grid item xs={12} sm={6} key={index}>
                    <MetricBox>
                      <Box
                        sx={{ display: "flex", alignItems: "center", gap: 1.5 }}
                      >
                        <IconWrapper bgcolor={metric.bgcolor}>
                          {metric.icon}
                        </IconWrapper>
                        <Box>
                          <Typography
                            variant="body1"
                            sx={{
                              fontWeight: 600,
                              color: "#111827",
                            }}
                          >
                            {metric.value}
                          </Typography>
                          <Typography
                            variant="body2"
                            sx={{
                              color: "#6b7280",
                            }}
                          >
                            {metric.label}
                          </Typography>
                        </Box>
                      </Box>
                    </MetricBox>
                  </Grid>
                ))}
              </Grid>
            </CardContent>
          </StyledCard>
        </Grid>
      </Grid>

      {/* Settings Section - Both Toggles in Same Row */}
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          my: 3,
        }}
      >
        {/* <Typography
          variant="h5"
          sx={{ color: "#071251", fontWeight: "bold", ml: 1 }}
        >
          Game Settings
        </Typography> */}
      </Box>

      <Grid container spacing={6}>
        {/* Game Winning Type Toggle */}
        {/* <Grid item xs={12} md={6}>
          <Paper
            elevation={0}
            sx={{
              padding: "20px",
              borderRadius: "12px",
              backgroundColor: "#ffffff",
              border: "4px solid #eee",
              height: "100%",
              minHeight: "200px",
              transition:
                "transform 0.3s, box-shadow 0.3s, background 0.5s ease",
              "&:hover": {
                transform: "translateY(-2px)",
                boxShadow: "0 8px 16px rgba(0,0,0,0.1)",
                background:
                  "linear-gradient(140deg, #fff0b3 0%, #cec8f2 25%, #ffffff 60%)",
                border: "4px solid #f3f5ff",
              },
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                flexWrap: "wrap",
                gap: 2,
              }}
            >
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Casino
                  sx={{
                    color: "#071251",
                    fontSize: 38,
                    p: "6px",
                    bgcolor: "#ffffff",
                    borderRadius: "4px",
                    mr: "12px",
                  }}
                />
                <Typography
                  variant="h6"
                  sx={{ color: "#071251", fontWeight: "bold" }}
                >
                  Game Winning Type
                </Typography>
              </Box>
              <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                {isGameWinningLoading && (
                  <CircularProgress size={24} sx={{ color: "#071251" }} />
                )}
                <FormControlLabel
                  control={
                    <IOSSwitch
                      checked={isRandomWinning}
                      onChange={handleToggleGameWinningType}
                      sx={{ mx: 1 }}
                      disabled={isGameWinningLoading}
                    />
                  }
                  label={
                    <Typography
                      sx={{
                        color: "#666",
                        fontWeight: "medium",
                        textAlign: "center",
                      }}
                    >
                      {isRandomWinning ? "Random Winning" : "Least Bet"}
                    </Typography>
                  }
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    gap: 1,
                  }}
                />
              </Box>
            </Box>
            <Box sx={{ p: 1, mt: "4px", color: "#555", maxWidth: "400px" }}>
              Random Betting chooses a winner by luck, while Least Bet lets the
              smallest unique bet win, mixing chance and strategy.
            </Box>
          </Paper>
        </Grid> */}

        {/* Need to Deposit First Toggle */}
        {/* <Grid item xs={12} md={6}>
          <Paper
            elevation={0}
            sx={{
              padding: "20px",
              borderRadius: "12px",
              backgroundColor: "#ffffff",
              border: "4px solid #eee",
              height: "100%",
              minHeight: "200px",
              transition:
                "transform 0.3s, box-shadow 0.3s, background 0.5s ease",
              "&:hover": {
                transform: "translateY(-2px)",
                boxShadow: "0 8px 16px rgba(0,0,0,0.1)",
                background:
                  "linear-gradient(140deg, #fff0b3 0%, #cec8f2 25%, #ffffff 60%)",
                border: "4px solid #f3f5ff",
              },
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                flexWrap: "wrap",
              }}
            >
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <AccountBalance
                  sx={{
                    color: "#071251",
                    fontSize: 38,
                    p: "6px",
                    bgcolor: "#ffffff",
                    borderRadius: "4px",
                    mr: "12px",
                  }}
                />
                <Typography
                  variant="h6"
                  sx={{ color: "#071251", fontWeight: "bold" }}
                >
                  Need to Deposit First
                </Typography>
                </Box>
              
              <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                {isLoading && (
                  <CircularProgress size={24} sx={{ color: "#071251" }} />
                )}
                <FormControlLabel
                  control={
                    <IOSSwitch
                      checked={needToDepositFirst}
                      onChange={handleToggleDepositFirst}
                      sx={{ mx: 1 }}
                      disabled={isLoading}
                    />
                  }
                  label={
                    <Typography
                      sx={{
                        color: "#666",
                        fontWeight: "medium",
                      }}
                    >
                      {needToDepositFirst ? "Required" : "Optional"}
                    </Typography>
                  }
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    gap: 1,
                  }}
                />
              </Box>
              </Box>
              <Box sx={{ p: 1, mt: "4px", color: "#555", maxWidth: "400px" }}>
                'Required' means players must deposit to play, while 'Optional'
                allows play without deposit, giving flexibility to join as
                desired.
            </Box>
          </Paper>
        </Grid> */}
      </Grid>

      {/* Snackbar for notifications */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbar.severity}
          sx={{ width: "100%" }}
          elevation={6}
          variant="filled"
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default Dashboard;
