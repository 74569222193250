import React, { useEffect, useState } from "react";
import {
  Modal,
  Box,
  Typography,
  Button,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CloseIcon from "@mui/icons-material/Close";

const NotificationModal = ({ open, onClose }) => {
  const [shouldShow, setShouldShow] = useState(true);

  useEffect(() => {
    const lastConfirmedDate = localStorage.getItem("notificationConfirmedDate");
    const today = new Date().toDateString();

    if (lastConfirmedDate === today) {
      setShouldShow(false);
    } else {
      setShouldShow(true);
    }
  }, [open]);

  const handleConfirm = () => {
    const today = new Date().toDateString();
    localStorage.setItem("notificationConfirmedDate", today);
    onClose();
  };

  const handleClose = () => {
    onClose();
  };

  if (!shouldShow) {
    return null;
  }

  return (
    <Modal
      open={open}
      onClose={handleClose}
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: "20px",
      }}
    >
      <Box
        sx={{
          width: "90%",
          maxWidth: "333px",
          maxHeight: "444px",
          bgcolor: "#ffffff",
          borderRadius: "15px",
          boxShadow: 24,
          display: "flex",
          flexDirection: "column",
        }}
      >
        {/* Header Section */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            bgcolor: "#0f6518", // Change to yellow
            borderTopLeftRadius: "15px",
            borderTopRightRadius: "15px",
            p: 2,
            color: "#ffffff", // Black text for the header
            position: "relative",
          }}
        >
          <Typography
            variant="h6"
            sx={{ fontSize: "16px", textAlign: "center", fontWeight: "bold" }}
          >
            Notification
          </Typography>
          <CloseIcon
            sx={{
              cursor: "pointer",
              color: "#ffffff", // Black color for the close icon
              position: "absolute",
              right: "8px",
              top: "50%",
              transform: "translateY(-50%)",
            }}
            onClick={handleClose}
          />
        </Box>

        {/* Content Section */}
        <Box
          sx={{
            flex: 1,
            overflowY: "auto",
            p: 2,
            fontFamily: "Arial, sans-serif",
            fontWeight: "bold",
          }}
        >
          <Typography
            variant="body1"
            sx={{ fontWeight: "bold", textAlign: "left", mb: 2 }}
          >
            ❕Stay vigilant❕
          </Typography>
          <Typography variant="body1" sx={{ mb: 1, fontWeight: "bold" }}>
            <b>
              ❶. Stay vigilant against counterfeit websites that resemble our
              Dubai Win official site.
            </b>
          </Typography>
          <Typography variant="body1" sx={{ mb: 1, fontWeight: "bold" }}>
            <b>
              ❷. Avoid sending money to anyone, even if they claim to be an
              agent.
            </b>
          </Typography>
          <Typography variant="body1" sx={{ mb: 1, fontWeight: "bold" }}>
            <b>
              ❸. Do not disclose payment receipts or UTR numbers to anyone,
              including agents.
            </b>
          </Typography>
          <Typography
            variant="body1"
            sx={{ fontWeight: "bold", textAlign: "left", my: 2 }}
          >
            💭Welcome Dubai Win member💭
          </Typography>

          <Typography variant="body1" sx={{ fontWeight: "bold", mb: 1 }}>
            ⭐️ The best platform ever
          </Typography>
          <Typography variant="body1" sx={{ fontWeight: "bold", mb: 1 }}>
            ⭐️ The best variety game provider
          </Typography>
          <Typography variant="body1" sx={{ fontWeight: "bold", mb: 1 }}>
            ⭐️ Variety bonus provides
          </Typography>
          <Typography variant="body1" sx={{ fontWeight: "bold", mb: 1 }}>
            🔥 Super jackpot bonus
          </Typography>
          <Typography variant="body1" sx={{ fontWeight: "bold", mb: 1 }}>
            🔥 Variety agent bonus
          </Typography>
          <Typography variant="body1" sx={{ fontWeight: "bold", mb: 1 }}>
            🔥 Easy and faster deposit withdraw
          </Typography>

          <Typography variant="body1" sx={{ fontWeight: "bold", mt: 2 }}>
            Contact our team for help:
          </Typography>
          <Typography variant="body1" sx={{ mb: 1,fontWeight: "bold" }}>
            ✅ Self-Service (deposit, bank, account, change password)
          </Typography>
          <Typography variant="body1" sx={{ mb: 1, fontWeight: "bold" }}>
            ✅ Customer Service (game, balance)
          </Typography>
          <Typography variant="body1" sx={{ mb: 1,fontWeight: "bold" }}>
            ✅ Email (withdrawal)
          </Typography>
          <Typography variant="body1" sx={{ mb: 1, fontWeight: "bold" }}>
            ✅ Agent (bonus, gold)
          </Typography>
        </Box>

        {/* Confirm Button */}
        <Box
          sx={{
            textAlign: "center",
            p: 2,
            bgcolor: "#ffffff",
            borderTop: "1px solid #E0E0E0",
            borderBottomLeftRadius: "15px",
            borderBottomRightRadius: "15px",
          }}
        >
          <Button
            variant="contained"
            sx={{
              borderRadius: "20px",
              px: 3,
              py: 1,
              backgroundColor: "#0f6518", // Matching button color to yellow
              color: "#ffffff", // Black text
              "&:hover": {
                backgroundColor: "#0f6518", // Slightly darker yellow on hover
              },
            }}
            onClick={handleConfirm}
          >
            Confirm
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default NotificationModal;