import AdminPanel from './Admin';
import SystemSetting from './SystemSetting';


const System = ({}) => {
  return (
    <AdminPanel>
      <SystemSetting />
    </AdminPanel>
  );
};

export default System;