import React, { createContext, useContext, useState, useEffect } from "react";
import Cookies from "js-cookie";
import { jwtDecode } from "jwt-decode";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(() => {
    return sessionStorage.getItem("token") ? true : false;
  });

  const [isAdmin, setIsAdmin] = useState(() => {
    return sessionStorage.getItem("admin") ? true : false;
  });
  const [isSuperAdmin, setIsSuperAdmin] = useState(() => {
    return sessionStorage.getItem("superAdmin") ? true : false;
  });

  const isTokenExpired = () => {
    const token = sessionStorage.getItem("token");
    if (!token) {
      return true;
    }
    const decodedToken = jwtDecode(token);
    const expirationDate = new Date(decodedToken.exp * 1000);
    return expirationDate < new Date();
  };

  const checkAuthStatus = () => {
    const token = sessionStorage.getItem("token");
    setIsAuthenticated(!!token && !isTokenExpired());
    setIsAdmin(sessionStorage.getItem("admin") === "true");
    setIsSuperAdmin(sessionStorage.getItem("superAdmin") === "true");
  };

  const login = (token, admin, superAdmin) => {
    sessionStorage.setItem("token", token);
    Cookies.set("token", token);
    setIsAuthenticated(true);

    if (admin) {
      sessionStorage.setItem("admin", "true");
      setIsAdmin(true);
    } else {
      sessionStorage.removeItem("admin");
      setIsAdmin(false);
    }

    if (superAdmin) {
      sessionStorage.setItem("superAdmin", "true");
      setIsSuperAdmin(true);
    } else {
      sessionStorage.removeItem("superAdmin");
      setIsSuperAdmin(false);
    }
  };

  const logout = () => {
    sessionStorage.removeItem("token");
    Cookies.remove("token");
    setIsAuthenticated(false);

    sessionStorage.removeItem("admin");
    setIsAdmin(false);

    sessionStorage.removeItem("superAdmin");
    setIsSuperAdmin(false);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (isTokenExpired()) {
        logout();
      }
    }, 60000); // check every minute (60000 ms)

    return () => clearInterval(interval);
  }, []);

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        isAdmin,
        isSuperAdmin,
        login,
        logout,
        checkAuthStatus,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
