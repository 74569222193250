// **************LocalHost******************

// export const domain = 'http://localhost:4000';
// export const wssdomain = 'ws://localhost:4000';


// **************Production******************

export const domain = 'https://dubaiwin.online';
export const wssdomain = 'wss://dubaiwin.online';

// **************Production2******************

// export const domain = 'https://anotechsolutions.in';
// export const wssdomain = 'wss://anotechsolutions.in';