import React, { useState, useEffect } from "react"
import Button from "@mui/material/Button"
import {
  Typography,
  Tabs,
  Tab,
  Box,
  Badge,
  IconButton,
  TextField,
  Paper,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material"
import { domain } from "../../Components/config"
import axios from "axios"
import { DataGrid } from "@mui/x-data-grid"
import { useNavigate } from "react-router-dom"
import SearchIcon from "@mui/icons-material/Search"
import RefreshIcon from "@mui/icons-material/Refresh"
import { createTheme, ThemeProvider } from "@mui/material/styles"
import DesktopWindowsOutlinedIcon from '@mui/icons-material/DesktopWindowsOutlined';

const theme = createTheme({
  palette: {
    primary: {
      main: "#000",
    },
    secondary: {
      main: "#071251",
    },
  },
  typography: {
    fontWeightBold: 700,
  },
})

const BannedMembersContent = () => {
  const [users, setUsers] = useState([])
  const [lockedUsers, setLockedUsers] = useState([])
  const [tabIndex, setTabIndex] = useState(0)
  const [searchTerm, setSearchTerm] = useState("")
  const [searchType, setSearchType] = useState("mobile")
  const [showSearchForm, setShowSearchForm] = useState(false)
  const navigate = useNavigate()

  useEffect(() => {
    fetchUsers()
  }, [])

  const fetchUsers = () => {
    axios
      .get(`${domain}/fetchuserdetails`, { withCredentials: true })
      .then((res) => {
        const allUsers = res.data.users
        setUsers(allUsers.filter((user) => !user.locked))
        setLockedUsers(allUsers.filter((user) => user.locked))
      })
      .catch((error) => {
        console.error(error)
      })
  }

  // const handleLockUser = (mobile) => {
  //   axios
  //     .delete(`${domain}/deleteuser`, {
  //       data: { mobile: mobile },
  //       withCredentials: true,
  //     })
  //     .then(() => {
  //       fetchUsers();
  //     })
  //     .catch((error) => {
  //       console.error("Error locking user:", error);
  //     });
  // };

  // const handleUnlockUser = (mobile) => {
  //   axios
  //     .put(
  //       `${domain}/unlockuser`,
  //       { mobile: mobile },
  //       { withCredentials: true }
  //     )
  //     .then(() => {
  //       fetchUsers();
  //     })
  //     .catch((error) => {
  //       console.error("Error unlocking user:", error);
  //     });
  // };

  const handleProfile = (_id) => {
    navigate(`/profile/${_id}`)
  }

  const columns = [
    {
      field: "id",
      headerName: "Sr No",
      width: 100,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "username",
      headerName: "Username",
      width: 180,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "mobile",
      headerName: "Mobile",
      width: 180,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "uid",
      headerName: "UID",
      width: 150,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "walletAmount",
      headerName: "Wallet Amount",
      width: 150,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "plainPassword",
      headerName: "Password",
      width: 150,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "action",
      headerName: "Action",
      sortable: false,
      width: 200,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        const onProfileClick = () => {
          handleProfile(params.row._id)
        }

        return (
          <Box>
            <Button
              variant="outlined"
              onClick={onProfileClick}
              sx={{
                color: "#4634FF", 
                borderColor: "#4634FF", 
                fontWeight: "bold",
                textTransform: "none",
                "&:hover": {
                  border: "none", 
                  backgroundColor: "#4634FF",
                  color: "#ffffff",
                },
              }}
            >
              <DesktopWindowsOutlinedIcon fontSize="small" sx={{ mr:1 }}/>
              Details
            </Button>
          </Box>
        )
      },
    },
  ]

  // const handleTabChange = (event, newValue) => {
  //   setTabIndex(newValue);
  // };

  const handleSearch = () => {
    if (searchTerm.trim() === "") {
      fetchUsers()
    } else {
      const filteredUsers = users.filter((user) =>
        user[searchType]
          .toString()
          .toLowerCase()
          .includes(searchTerm.toLowerCase())
      )
      const filteredLockedUsers = lockedUsers.filter((user) =>
        user[searchType]
          .toString()
          .toLowerCase()
          .includes(searchTerm.toLowerCase())
      )
      setUsers(filteredUsers)
      setLockedUsers(filteredLockedUsers)
    }
  }

  const toggleSearchForm = () => {
    setShowSearchForm(!showSearchForm)
  }

  const handleReload = () => {
    fetchUsers()
    setSearchTerm("")
    setSearchType("mobile")
  }

  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          p: 3,
          backgroundColor: "white",
          boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
          borderRadius: 1,
        }}
      >
        <Typography
          variant="h5"
          sx={{
            textAlign: "left",
            fontWeight: "bold",
            mb: 3,
            color: "#071251",
          }}
        >
          Banned Users
        </Typography>
        <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
          <FormControl
            variant="outlined"
            size="small"
            sx={{ minWidth: 120, mr: 2 }}
          >
            <InputLabel>Search By</InputLabel>
            <Select
              value={searchType}
              onChange={(e) => setSearchType(e.target.value)}
              label="Search By"
            >
              <MenuItem value="mobile">Mobile</MenuItem>
              <MenuItem value="uid">UID</MenuItem>
            </Select>
          </FormControl>
          <TextField
            variant="outlined"
            size="small"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            placeholder={`Search user by ${searchType}`}
            InputProps={{ inputProps: { min: 0 } }}
            sx={{
              width: "60%",
              mr: 2,
              "& .MuiOutlinedInput-root.Mui-focused": {
                "& fieldset": {
                  borderColor: "black",
                },
              },
              marginBottom: { xs: "10px", sm: "0" },
            }}
          />
          <IconButton onClick={handleSearch} color="primary">
            <SearchIcon />
          </IconButton>
          <IconButton onClick={handleReload} color="primary">
            <RefreshIcon />
          </IconButton>
        </Box>
        <Paper
          elevation={3}
          sx={{ boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.1)" }}
        >
          <Box sx={{ height: 400, width: "100%" }}>
            <DataGrid
              rows={lockedUsers.map((user, index) => ({
                ...user,
                id: index + 1,
              }))}
              columns={columns}
              pageSize={5}
              rowsPerPageOptions={[25]}
              sx={{
                backgroundColor: "white",
                "& .super-app-theme--header": {
                  fontWeight: "bold",
                },
              }}
            />
          </Box>
        </Paper>
      </Box>
    </ThemeProvider>
  )
}

export default BannedMembersContent