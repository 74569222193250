import React, { useState, useCallback, useRef, useEffect } from "react";
import Tree from "react-d3-tree";
import axios from "axios";
import {
  Card,
  CardContent,
  TextField,
  Button,
  Typography,
  Alert,
  Box,
  CircularProgress,
  Paper,
  IconButton,
  Tooltip,
  Stack,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import ZoomOutIcon from "@mui/icons-material/ZoomOut";
import CenterFocusStrongIcon from "@mui/icons-material/CenterFocusStrong";
import { styled } from "@mui/material/styles";
import { domain } from "../../Components/config";

const StyledCard = styled(Card)(({ theme }) => ({
  width: "100%",
  background: theme.palette.background.paper,
  boxShadow: theme.shadows[3],
}));

const SearchButton = styled(Button)(({ theme }) => ({
  padding: "6px 12px",
  fontSize: "0.875rem",
  backgroundColor: "#0f6518",
  "&:hover": {
    backgroundColor: "#0b4912",
  },
}));

const TreeContainer = styled(Paper)(({ theme }) => ({
  width: "100%",
  height: "600px",
  background: theme.palette.background.default,
  borderRadius: theme.shape.borderRadius,
  overflow: "hidden",
  position: "relative",
}));

const ControlsContainer = styled(Box)(({ theme }) => ({
  position: "absolute",
  right: theme.spacing(2),
  top: theme.spacing(2),
  zIndex: 1000,
  background: theme.palette.background.paper,
  padding: theme.spacing(1),
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[2],
}));

const GreenButton = styled(Button)(({ theme }) => ({
  borderColor: "#0f6518",
  color: "#0f6518",
  "&:hover": {
    borderColor: "#0b4912",
    backgroundColor: "rgba(15, 101, 24, 0.04)",
  },
}));

const GreenIconButton = styled(IconButton)(({ theme }) => ({
  color: "#0f6518",
  "&:hover": {
    backgroundColor: "rgba(15, 101, 24, 0.04)",
  },
}));


const ReferralTree = () => {
  const [uid, setUid] = useState("");
  const [treeData, setTreeData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [adminTree, setAdminTree] = useState(null);
  const [zoom, setZoom] = useState(0.8);
  const treeContainerRef = useRef(null);
  const [translate, setTranslate] = useState({ x: 400, y: 100 });

  useEffect(() => {
    fetchAdminTree();
  }, []);

  const fetchAdminTree = async () => {
    try {
      setLoading(true);
      const response = await axios.get(`${domain}/get-admin-tree`, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });

      if (response.data.status === 'success' && response.data.data.length > 0) {
        const formattedAdminTree = {
          name: response.data.data[0].name,
          attributes: {
            ...response.data.data[0].attributes,
            depth: 0
          },
          children: formatChildren(response.data.data[0].children || [])
        };
        setAdminTree([formattedAdminTree]);
        setTreeData(null);
        handleCenterTree();
      } else {
        setError('No admin tree data available');
      }
    } catch (error) {
      console.error('Error fetching admin tree:', error);
      setError('Failed to load admin tree');
    } finally {
      setLoading(false);
    }
  };

  const formatChildren = (children, depth = 1) => {
    return children.map(child => ({
      name: child.name,
      attributes: {
        ...child.attributes,
        depth: depth
      },
      children: formatChildren(child.children || [], depth + 1)
    }));
  };

  const CustomNode = React.memo(({ nodeDatum, toggleNode }) => {
    const isRoot = !nodeDatum.attributes?.depth;
    const hasChildren = nodeDatum.children && nodeDatum.children.length > 0;
    const hasNoReferrals = nodeDatum.attributes?.noReferrals;
  
    const handleClick = (e) => {
      e.stopPropagation();
      if (hasChildren) {
        toggleNode();
      } else if (hasNoReferrals) {
        alert("This UID doesn't have any referred users.");
      }
    };
  
    return (
      <g onClick={handleClick}>
        <defs>
          <linearGradient id="rootGradient" x1="0%" y1="0%" x2="0%" y2="100%">
            <stop offset="0%" style={{ stopColor: '#0f6518', stopOpacity: 1 }} />
            <stop offset="100%" style={{ stopColor: '#ffffff', stopOpacity: 1 }} />
          </linearGradient>
        </defs>
  
        <rect
          x="-65"
          y="-32"
          width="130"
          height="64"
          rx="6"
          fill={isRoot ? 'url(#rootGradient)' : '#e8f5e9'}
          stroke={isRoot ? '#0f6518' : '#81c784'}
          strokeWidth={isRoot ? 2 : 1}
          style={{ cursor: 'pointer' }}
        />
  
        <g>
          <text
            y={isRoot ? "0" : "-10"} 
            x="0"
            textAnchor="middle"
            style={{ 
              fill: isRoot ? '#000000' : '#0f6518',
              fontSize: isRoot ? '16px' : '12px',
              fontWeight: isRoot ? 'bold' : '400'
            }}
          >
             UID:{nodeDatum.name}
          </text>
          <text
            y="20"
            x="0"
            textAnchor="middle"
            style={{ 
              fill: isRoot ? '#ffffff' : '#2e7d32',
              fontSize: '11px'
            }}
          >
            {isRoot ? '' : (hasNoReferrals ? 'No referrals' : `Ph: ${nodeDatum.attributes?.Mobile || 'N/A'}`)}
          </text>
        </g>
  
        {hasChildren && !nodeDatum.__rd3t.collapsed && (
          <polygon
            points="-5,48 0,58 5,48"
            fill={isRoot ? '#ffffff' : '#0f6518'}
            transform="translate(0, 3)"
          />
        )}
      </g>
    );
  });

  const handleZoomIn = () => {
    setZoom((prevZoom) => Math.min(prevZoom + 0.2, 2));
  };

  const handleZoomOut = () => {
    setZoom((prevZoom) => Math.max(prevZoom - 0.2, 0.3));
  };

  const handleCenterTree = () => {
    if (treeContainerRef.current) {
      const containerWidth = treeContainerRef.current.offsetWidth;
      setTranslate({ x: containerWidth / 2, y: 100 });
      setZoom(0.8);
    }
  };

  const handleSearch = useCallback(async () => {
    if (!uid.trim()) {
      setError("Please enter a UID");
      return;
    }
    setLoading(true);
    setError("");
    setTreeData(null);

    try {
      const response = await axios.get(`${domain}/get-referred-users`, {
        params: { uid: uid.trim() },
      });

      if (response.data.status === "success") {
        if (!response.data.referralTree.length) {
          const singleNodeData = [{
            name: uid.trim(),
            attributes: {
              Username: "User " + uid.trim(),
              Mobile: response.data.userData.mobile || "N/A",
              noReferrals: true,
              depth: 0
            },
            children: [],
          }];
          setTreeData(singleNodeData);
        } else {
          setTreeData(formatTreeData(uid.trim(), response.data.referralTree));
        }
        handleCenterTree();
      }
    } catch (err) {
      console.error("Error fetching referred users:", err);
      setError(err.response?.data?.message || "Failed to retrieve data. Please try again.");
    } finally {
      setLoading(false);
    }
  }, [uid]);

  const formatTreeData = (rootUid, referredUsers) => {
    const root = {
      name: rootUid,
      attributes: {
        Username: "Root User",
        Mobile: referredUsers[0]?.mobile || "N/A",
        depth: 0
      },
      children: referredUsers.map((user) => formatUserNode(user))
    };
    return [root];
  };

  const formatUserNode = (user, depth = 1) => ({
    name: user.uid,
    attributes: {
      Username: `User ${user.uid}`,
      Mobile: user.mobile || "N/A",
      depth: depth
    },
    children: user.referredUsers.map((referredUser) =>
      formatUserNode(referredUser, depth + 1)
    )
  });

  const resetToAdminTree = () => {
    setTreeData(null);
    setUid('');
    setError('');
    if (adminTree) {
      handleCenterTree();
    } else {
      fetchAdminTree();
    }
  };

  return (
    <StyledCard>
      <CardContent>
        <Box sx={{ display: "flex", alignItems: "center", mb: 3 }}>
          <AccountTreeIcon sx={{ mr: 1, color: "#0f6518" }} />
          <Typography variant="h5" component="h2" sx={{ color: "#0f6518" }}>
            Referral Tree
          </Typography>
        </Box>

        <Stack direction="row" spacing={2} sx={{ mb: 3 }}>
          <TextField
            value={uid}
            onChange={(e) => setUid(e.target.value)}
            placeholder="Enter a six digit UID"
            variant="outlined"
            size="small"
            sx={{
              maxWidth: 300,
              backgroundColor: "#f5f5f5",
              '& .MuiOutlinedInput-root': {
                '&.Mui-focused fieldset': {
                  borderColor: '#0f6518',
                },
              },
            }}
          />
          <SearchButton
            variant="contained"
            onClick={handleSearch}
            disabled={loading}
            startIcon={loading ? <CircularProgress size={20} /> : <SearchIcon />}
          >
            Search
          </SearchButton>

          <GreenButton
            variant="outlined"
            onClick={resetToAdminTree}
            disabled={loading}
            startIcon={<AccountTreeIcon />}
          >
            Show My Tree
          </GreenButton>
        </Stack>

        {error && (
          <Alert severity="error" sx={{ mb: 3, width: "100%", maxWidth: 500 }}>
            {error}
          </Alert>
        )}

        <TreeContainer ref={treeContainerRef} elevation={1}>
          <ControlsContainer>
            <Stack direction="row" spacing={1}>
              <Tooltip title="Zoom In">
                <GreenIconButton onClick={handleZoomIn} size="small">
                  <ZoomInIcon />
                </GreenIconButton>
              </Tooltip>
              <Tooltip title="Zoom Out">
                <GreenIconButton onClick={handleZoomOut} size="small">
                  <ZoomOutIcon />
                </GreenIconButton>
              </Tooltip>
              <Tooltip title="Center Tree">
                <GreenIconButton onClick={handleCenterTree} size="small">
                  <CenterFocusStrongIcon />
                </GreenIconButton>
              </Tooltip>
            </Stack>
          </ControlsContainer>

          {(treeData || adminTree) && (
            <Tree
              data={treeData || adminTree}
              orientation="vertical"
              renderCustomNodeElement={(rd3tProps) => (
                <CustomNode {...rd3tProps} />
              )}
              zoom={zoom}
              translate={translate}
              onUpdate={(state) => {
                setTranslate(state.translate);
                setZoom(state.zoom);
              }}
              enableLegacyTransitions={true}
              transitionDuration={400}
              separation={{ siblings: 1.5, nonSiblings: 2 }}
              nodeSize={{ x: 150, y: 120 }}
              pathFunc="step"
              pathClassFunc={() => "path-link"}
              draggable={true}
              scaleExtent={{ min: 0.3, max: 2 }}
              collapsible={true}
            />
          )}
        </TreeContainer>
      </CardContent>

      <style jsx global>{`
        .path-link {
          stroke: #0f6518;
          stroke-width: 1.5;
          transition: stroke 0.2s;
        }
        .path-link:hover {
          stroke: #0b4912;
        }
        .rd3t-node {
          cursor: grab;
          stroke-width: 1;
        }
        .rd3t-node:active {
          cursor: grabbing;
        }
      `}</style>
    </StyledCard>
  );
};

export default ReferralTree;